// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-click-a-dot-js": () => import("./../../../src/pages/click-a-dot.js" /* webpackChunkName: "component---src-pages-click-a-dot-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-get-to-know-you-js": () => import("./../../../src/pages/get-to-know-you.js" /* webpackChunkName: "component---src-pages-get-to-know-you-js" */),
  "component---src-pages-hearing-test-js": () => import("./../../../src/pages/hearing-test.js" /* webpackChunkName: "component---src-pages-hearing-test-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-required-reading-js": () => import("./../../../src/pages/required-reading.js" /* webpackChunkName: "component---src-pages-required-reading-js" */),
  "component---src-pages-thumb-singing-js": () => import("./../../../src/pages/thumb-singing.js" /* webpackChunkName: "component---src-pages-thumb-singing-js" */),
  "component---src-pages-you-are-thumb-one-js": () => import("./../../../src/pages/you-are-thumb-one.js" /* webpackChunkName: "component---src-pages-you-are-thumb-one-js" */)
}

